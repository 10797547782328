<template>
  <b-form @submit.prevent>
    <b-row>
      <b-col md="6">
        <b-form-group label="Raw Material Name" label-for="mc-Raw Material-name">
          <b-form-input type="text" id="mc-Raw Material-name" placeholder="Raw Material Name" v-model="name" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Raw Material Code" label-for="mc-Raw Material-Code">
          <b-form-input type="text" id="mc-Raw Material-Code" placeholder="Raw Material Code" v-model="code" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Low Stock Threshold">
          <div class="form-label-group">
            <b-form-input id="mc-stock" type="number" placeholder="Low Stock Threshold" v-model="lowStock" />
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="mc-stock-threshold" label="Unit of Measure (UOM)">
          <b-form-select v-model="defaultUomID" :options="itemUoms" value-field="id" text-field="name" />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group label="Price (Rs)" label-for="Price">
          <b-form-input type="number" id="mc-Raw Material-Price" placeholder="Raw Material Price"
            v-model="purchasePrice" />
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Category" label-for="mc-Category">
          <b-form-select v-model="categoryID" :options="itemCategories" value-field="id" text-field="name" />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Preferred Supplier" label-for="mc-Supplier">
          <b-form-select v-model="supplierId" :options="suppliers" value-field="id" text-field="name" />
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mb-2">
        <label for="textarea-default">Raw Material Description</label>
        <b-form-textarea id="textarea-default" placeholder="Raw Material Description" rows="5" v-model="description" />
      </b-col>
      <!-- submit and reset -->
      <b-col>
        <b-button type="submit" variant="primary" @click="addRawMaterial()"><b-spinner small v-if="loading"
            class="mx-2" />Save </b-button>&nbsp;
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormDatepicker,
  BFormGroup,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AddRawMaterial",
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormDatepicker,
    BFormGroup,
    BButton,
    BSpinner,
  },
  data() {
    return {
      name: "",
      description: "",
      categoryID: "",
      code: "",
      supplierId: "",
      lowStock: "",
      defaultUomID: "",
      purchasePrice: "",
    };
  },
  methods: {
    success() {
      this.$swal({
        title: "Raw Material Added!",
        text: "You have successfully added the raw material!",
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$router.push({ name: "rawmaterials-list" });
        }
      });
    },

    // error
    error(data) {
      let msg = null;
      if (data) msg = this.getErrorString(data);
      this.$swal({
        title: "Error!",
        //text: msg ?? ' Failed!',
        html: msg ?? " Failed!",
        icon: "error",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    getErrorString(data) {
      let msg = null;
      if (data.errors) {
        let errors = data.errors;
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        for (var prop in errors) {
          if (Object.prototype.hasOwnProperty.call(errors, prop) && prop !== "request") {
            msg += "<p>";
            msg += prop.replace("$.", "");
            msg += ": ";
            msg += errors[prop].toString().replace("$.", "");
            msg += "</p>";
          }
        }
        msg += "</div>";
      } else if (data.message) {
        msg = "<div>";
        msg += "<p>Cannot post your request due to the following errors:</p>";
        msg += data.message;
        msg += "</div>";
      }
      return msg;
    },
    ...mapActions("rawmaterialModule", ["addRawMaterialAction"]),
    ...mapActions("itemUomModule", ["getItemUOMListAction"]),
    ...mapActions("itemcategoryModule", ["getItemCategoryListAction"]),
    ...mapActions("supplierModule", [
            "getSupplierListAction"
        ]),
    addRawMaterial() {
      let payload = {
        name: this.name,
        description: this.description,
        categoryID: this.categoryID,
        code: this.code,
        supplierId: this.supplierId,
        lowStock: this.lowStock,
        defaultUomID: this.defaultUomID,
        purchasePrice: this.purchasePrice,
      };

      this.addRawMaterialAction(payload)
        .then(() => {
          this.success();
        })
        .catch((error) => {
          this.error(error.response.data);
        });
    },
  },
  computed: {
    ...mapGetters("itemUomModule", {
      itemUoms: "itemuom",
      loading: "loading",
    }),
    ...mapGetters("itemcategoryModule", {
      itemCategories: "itemcategories",
      loading: "loading",
    }),
    ...mapGetters("rawmaterialModule", {
      loading: "loading",
    }),
    ...mapGetters("supplierModule", {            
            suppliers: "suppliers",
            loading: "loading"
        })
  },
  async mounted() {
    await this.getItemUOMListAction();
    await this.getItemCategoryListAction();
    await this.getSupplierListAction();
  },
};
</script>
